<template>
  <h1 data-testid="event-list-title">Events for Good</h1>
  <div class="events">
    <router-link
      class="event-link"
      :to="{ name: 'EventDetails', params: { id: event.id } }"
      v-for="event in events"
      :key="event.id"
    >
      <EventCard :event="event" />
    </router-link>

    <div class="pagination">
      <router-link
        id="page-prev"
        :to="{ name: 'EventList', query: { page: page - 1 } }"
        rel="prev"
        v-if="hasPreviousPage"
        >&#60; Previous</router-link
      >

      <!-- <div v-if="totalPages">
        <router-link
          class="page-number-link"
          v-for="page in parseInt(totalPages)"
          v-text="page"
          :key="page"
          :to="{ name: 'EventList', query: { page: page } }"
        ></router-link>
      </div> -->

      <router-link
        id="page-next"
        :to="{ name: 'EventList', query: { page: page + 1 } }"
        rel="next"
        v-if="hasNextPage"
        >Next &#62;</router-link
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import EventCard from '@/components/EventCard.vue'
// import EventService from '@/services/EventService.js'
//import { watchEffect } from 'vue'
//import NProgress from 'nprogress'

export default {
  name: 'EventList',
  props: ['page'],
  components: {
    EventCard,
  },
  // data() {
  //   return {
  //     events: null,
  //     totalEvents: 0,
  //   }
  // },
  // beforeRouteEnter(routeTo, routeFrom, next) {
  //NProgress.start()
  // EventService.getEvents(2, parseInt(routeTo.query.page) || 1)
  //   .then((response) => {
  //     next((comp) => {
  //       comp.events = response.data
  //       comp.totalEvents = response.headers['x-total-count']
  //     })
  //   })
  //   .catch((error) => {
  //     //this.$router.push({ name: 'NetworkError' })
  //     next({ name: 'NetworkError' })
  //   })
  //.finally(() => {
  //  NProgress.done()
  //})
  // },
  // beforeRouteUpdate(routeTo) {
  //NProgress.start()
  // return EventService.getEvents(2, parseInt(routeTo.query.page) || 1)
  //   .then((response) => {
  //     this.events = response.data
  //     this.totalEvents = response.headers['x-total-count']
  //   })
  //   .catch((error) => {
  //     //this.$router.push({ name: 'NetworkError' })
  //     return { name: 'NetworkError' }
  //   })
  //.finally(() => {
  //  NProgress.done()
  //})
  // },
  /*created() {
    watchEffect(() => {
      this.events = null
      EventService.getEvents(2, this.page)
        .then((response) => {
          this.events = response.data
          this.totalEvents = response.headers['x-total-count']
        })
        .catch((error) => {
          this.$router.push({ name: 'NetworkError' })
        })
    })
  },*/
  created() {
    this.$store.dispatch('fetchEvents').catch((error) => {
      this.$router.push({ name: 'ErrorDisplay', params: { error: error } })
    })
  },
  computed: {
    totalPages() {
      return 1
      // return Math.ceil(this.totalEvents / 2)
    },
    hasPreviousPage() {
      return false
      // return this.page != 1
    },
    hasNextPage() {
      return false
      // return this.page < this.totalPages
    },
    events() {
      return this.$store.state.events
    },
  },
}
</script>

<style scoped>
.events {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pagination {
  display: flex;
  width: 290px;
}
.pagination a {
  flex: 1;
  text-decoration: none;
  color: #2c3e50;
}
.page-number-link {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
#page-prev {
  text-align: left;
}
#page-next {
  text-align: right;
}
.event-link {
  color: #2c3e50;
  text-decoration: none;
}
</style>
