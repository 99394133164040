<template>
  <p>Register for the event</p>
  <button @click="register">Register Me</button>
</template>

<script>
export default {
  props: ['event'],
  inject: ['GStore'],
  methods: {
    register() {
      // If registration API call is successful
      // Push back to the event details
      this.GStore.flashMessage =
        'You are successfully registed for ' + this.event.title
      setTimeout(() => {
        this.GStore.flashMessage = ''
      }, 3000)
      this.$router.push({
        name: 'EventDetails',
        //params: { id: this.event.id },
      })
    },
  },
}
</script>
